.title {
    font-size: 30px !important;
    font-weight: 550 !important;
    color: #5FB878 !important;
    display: inline-block !important;

}

/* 登录按钮 */
.loginButton {
    width: 100%;
    background-color: #5FB878;
    color: white;
    height: 44px;
    font-size: 16px;
    font-weight: 550;
}
.loginButton:focus {
    border: 1px solid #5FB878 !important; 
}

.loginButton:hover {
    border: 1px solid #5FB878 !important; 
    color: white !important;
}

/* 登录表单 */
.inputStyle{
    height: 44px;
    width: 100%
}

 /* 登录页面 */
.layout {
    background: none;
}
/* 在小屏幕上设置高度为70% */
@media screen and (max-width: 767px) {
    .layout {
      height: 80vh;
    }
  }
  /* 在大屏幕上设置高度为50% */
@media screen and (min-width: 768px) {
    .layout {
      height: 50vh;
    }
  }